import React from "react";
import MissionAndVision from "./MissionAndVision";

const About = () => {
  return (
    <div className="mt-4">
      <MissionAndVision />
    </div>
  );
};

export default About;
