import React from "react";
import "../css/contact.css";

const PersonalCare = () => {
  return (
    <div>
      <div>
        <div className="container py-2 px-5">
          <div className="row">
            <div className="col-md-6">
              <div>
                <p className="text-dark" style={{ marginTop: 20 }}>
                  Do you or a loved one need a helping hand to maintain
                  independence and comfort at home? Our personal care services
                  are designed to provide the perfect blend of assistance and
                  companionship. We understand that everyone's needs are unique,
                  so we create personalized care plans that encompass a wide
                  range of daily living activities.
                </p>
                <p className="text-dark" style={{ marginTop: 10 }}>
                  Our compassionate caregivers can assist with essential tasks
                  like bathing, dressing, and medication reminders. They can
                  also help with light housekeeping, meal preparation, and
                  errands, ensuring your home remains a safe and manageable
                  environment. But our services go beyond just the physical. Our
                  caregivers are trained companions who can provide social
                  interaction, emotional support, and friendly conversation,
                  reducing feelings of isolation and loneliness.
                </p>
                <p className="text-dark" style={{ marginTop: 10 }}>
                  Whether you need short-term assistance after an illness or
                  ongoing care due to aging, we're here to help. Let us discuss
                  your specific needs and create a personalized plan that
                  promotes dignity, well-being, and continued independence in
                  the comfort of your own home.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="global-section">
                <img
                  className="global-image"
                  // src={require("../Images/Services-Physical_Disability_Care.jpg")}
                  src={require("../Images/5.jpg")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalCare;
