import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../css/Menu.css";

const Menu = () => {
  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
          <Navbar.Brand href="/">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <img src={require("../Images/logo2.jpeg")} className="logo" />
              </div>

              <div className="site-title">
                <h3 className="text-dark ms-3 mt-2">
                  Affectionate Angels Healthcare
                </h3>
              </div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto menu">
              <Nav.Link href="/">Home</Nav.Link>

              <Nav.Link href="/why-choose-us" className="link">
                Why Choose Us
              </Nav.Link>
              <Nav.Link href="/about" className="link">
                About Us
              </Nav.Link>
              {/* <div className="paste-button">
                <Nav.Link className="nav-button">Why Choose All Angel</Nav.Link>
                <div className="dropdown-contents">
                  <a id="top" href="#">
                    Why Choose Us
                  </a>
                  <a id="middle" href="#">
                    About Us
                  </a>
                </div>
              </div> */}
              <div className="paste-button">
                {/* <button class="nav-button">Paste &nbsp; ▼</button> */}
                <Nav.Link className="nav-button">Services</Nav.Link>
                <div className="dropdown-contents">
                  <Nav.Link id="top" href={"/PersonalCare"}>
                    Personal Care
                  </Nav.Link>
                  <Nav.Link id="middle" href={"/Nursing"}>
                    Nursing Services
                  </Nav.Link>
                  <Nav.Link id="middle" href={"/Companion"}>
                    Companion Services
                  </Nav.Link>
                </div>
              </div>
              {/* <Nav.Link href="" className="link">
                U.S VETERANS
              </Nav.Link> */}
              {/* <Nav.Link href="" className="link">
                Employment Application
              </Nav.Link> */}

              {/* <Nav.Link href="" className="link">
                Care Tips
              </Nav.Link> */}

              <Nav.Link href="/Contact" className="link">
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Menu;
