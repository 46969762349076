import React, { useState } from "react";
import "../css/contact.css";
import helper from "../helper/helper";
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleFormSubmission = () => {
    if (formData.name === "") {
      helper.showMessage({
        title: "Attention",
        text: "Please enter your name",
        icon: "error",
      });
    } else if (formData.phone === "") {
      helper.showMessage({
        title: "Attention",
        text: "Please enter your phone number",
        icon: "error",
      });
    } else if (formData.email === "") {
      helper.showMessage({
        title: "Attention",
        text: "Please enter your email",
        icon: "error",
      });
    } else {
      // verify email
      if (!helper.verifyEmail(formData.email)) {
        helper.showMessage({
          title: "Attention",
          text: "Please enter a valid email address",
          icon: "error",
        });
      } else {
        helper.showDialog({
          title: "Attention",
          text: "Are you sure you want to send this message?",
          icon: "info",
          showCancelButton: true,
          cancelButtonColor: "red",
          confirmButtonColor: "green",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          proceed: () => {
            helper.showLoading();

            const data = {
              broke: "ap",
              msg: `<div style="font-size:2rem"><p>New Contact from website</p>
              <br>
              
              <p>Name: ${formData.name}</p>
              <p>Phone Number: ${formData.phone}</p>
              <p>Email: ${formData.email}</p>
              <p>message: ${formData.message}</p>
             
              </div>
              `,
            };

            return fetch(
              "https://affectionateangelshealthcare.com/mpi/contact.php",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            )
              .then((response) => {
                helper.hideLoading();
                response.json();
              })
              .then(() => {
                helper.showMessage({
                  title: "Attention",
                  text: "Message sent successfully",
                  icon: "success",
                });
              })
              .catch((err) => {
                helper.hideLoading();
                helper.showMessage({
                  title: "Attention",
                  text: "Something went wrong. Please try again later",
                  icon: "error",
                });
              });
          },
          cancel: () => {},
        });
      }
    }
  };
  return (
    <div>
      <div className="container py-2 px-5">
        <div className="row">
          <div className="col-md-6">
            <h3 className="text-dark header">Get In Touch</h3>
            <h4 className="text-dark c-info">
              Fill in the form and we will get back to you:
            </h4>
            <p className="text-muted">Fields marked with an * are required</p>
            <form>
              <div class="form-group mb-3">
                <input
                  type="text"
                  className="form-control py-2"
                  aria-describedby="Name"
                  placeholder="Name*"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                />
              </div>
              {/* phone number */}
              <div class="form-group mb-3">
                <input
                  type="text"
                  className="form-control py-2"
                  aria-describedby="Phone number"
                  placeholder="Phone Number*"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      phone: e.target.value,
                    }))
                  }
                />
              </div>

              {/* email */}
              <div class="form-group mb-3">
                <input
                  type="email"
                  className="form-control py-2"
                  aria-describedby="Email"
                  placeholder="Email*"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }))
                  }
                />
              </div>

              {/* message */}
              <div class="form-group mb-3">
                <textarea
                  type="text"
                  className="form-control py-2"
                  aria-describedby="message"
                  placeholder="Your Message"
                  rows="8"
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      message: e.target.value,
                    }))
                  }
                >
                  {formData.message}
                </textarea>
              </div>

              <button
                type="button"
                onClick={handleFormSubmission}
                class="btn btn-primary btn-sm"
              >
                Submit
              </button>
            </form>
          </div>
          <div className="col-md-6">
            <div className="image-section">
              <img
                // className="c-image"
                className="global-image"
                // src={require("../Images/Services-Physical_Disability_Care.jpg")}
                // src={require("../Images/7.jpeg")}
                src={require("../Images/a4.jpg")}
              />
              <div>
                <h4 className="mt-2">Location:</h4>
                <p className="text-muted">
                  1636 Grassy Hill Ct, <br />
                  Grayson. GA, 30017 <br /> GE-222-7538
                </p>

                <a
                  href="tel:+1(774) 208-3004"
                  className="text-muted text-decoration-none"
                >
                  Tel: +1(774) 208-3004 <br />
                </a>
                <a
                  href="mailto:info@affectionateangleshealthcare.com"
                  className="text-muted text-decoration-none mb-5"
                >
                  Email: info@affectionateangelshealthcare.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
