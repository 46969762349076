import React from "react";
import "../css/SubHero.css";

const SubHero = () => {
  return (
    <>
      <div>
        <div className="subHeroSection">
          {/* image section */}
          <div className="container">
            <div className="row ">
              <div className="col-md-4">
                <p className="d-md-none text-dark card__title">
                  Finding the Best In-Home Care
                </p>
                <div class="card">
                  <img
                    // src={require("../Images/health1.jpeg")}
                    // src={require("../Images/6.jpeg")}
                    src={require("../Images/a2.jpeg")}
                    className="image"
                    alt=""
                  />
                  <div class="card__content">
                    <p class="card__title d-none d-md-block">
                      Finding the Best In-Home Care
                    </p>
                    <p class="card__description">
                      Feeling worried about your loved ones being alone?
                      Affectionate Angels Healthcare can provide compassionate
                      companions to keep them company and engaged. We'll work
                      with you to create a personalized plan that ensures they
                      have the social interaction and support they deserve.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <p className="d-md-none text-dark card__title">
                  Peace of mind for you, comfort and care for your loved ones.
                </p>
                <div class="card">
                  <img
                    // src={require("../Images/health2.jpeg")}
                    // src={require("../Images/5.jpg")}
                    src={require("../Images/a1.jpg")}
                    className="image"
                    alt=""
                  />
                  <div class="card__content">
                    <p class="card__title d-none d-md-block">
                      Peace of mind for you, comfort and care for your loved
                      ones.
                    </p>
                    <p class="card__description">
                      Finding the right home care can be stressful. At
                      Affectionate Angels Healthcare, our trustworthy caregivers
                      become like family, providing a helping hand with
                      everything from personal care and meals to medication
                      reminders and light housekeeping. We even offer
                      transportation, errand assistance, and emergency support
                      so you can focus on what matters most - quality time with
                      your loved ones.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <p className="d-md-none text-dark card__title">
                  Your Loved One's Happiness is Our Priority
                </p>
                <div class="card">
                  <img
                    // src={require("../Images/health3.jpeg")}
                    // src={require("../Images/8.jpeg")}
                    src={require("../Images/a3.jpg")}
                    className="image"
                    alt=""
                  />
                  <div class="card__content">
                    <p class="card__title d-none d-md-block">
                      Your Loved One's Happiness is Our Priority
                    </p>
                    <p class="card__description">
                      Want to see your loved one smiling every day? With
                      Affectionate Angels Healthcare, it's more than just a
                      wish. Our partnership puts your loved one's needs first,
                      with skilled caregivers who go beyond physical care to
                      nurture their mental well-being too. Experience the
                      difference of compassionate, personalized home care –
                      let's chat about creating a plan that brings joy back into
                      their lives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-lg-flex d-block justify-content-evenly align-md-center">




</div>  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubHero;
