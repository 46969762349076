import React from "react";
import "../css/Services.css";

const Services = () => {
  return (
    <>
      <div className="container services mb-3">
        {/* <h3 className="header">What Services We offer</h3> */}
        <h3 className="fs-1 text-bold">What Services We offer</h3>
        <p className="fs-5">
          A haven of compassionate care awaits your loved ones.
        </p>

        <div className="row mt-2">
          <div className="col-md-4">
            <div>
              <img
                // src={require("../Images/health1.jpeg")}
                src={require("../Images/4.jpg")}
                className="img-fluid"
                alt=""
              />
            </div>

            <h3 className="mt-2 fs-4">Personal Care </h3>

            <p className="description">
              Do you or a loved one need a helping hand to maintain independence
              and comfort at home? Our personal care services are designed to
              provide the perfect blend of assistance and companionship. We
              understand that everyone's needs are unique, so we create
              personalized care plans that include a wide range of daily living
              activities.
            </p>
          </div>
          <div className="col-md-4">
            <img
              // src={require("../Images/health2.jpeg")}
              // src={require("../Images/3.jpg")}
              src={require("../Images/a6.jpg")}
              className="img-fluid"
              alt=""
            />

            <h3 className="mt-2 fs-4">Companionship</h3>

            <p className="description">
              Do you crave stimulating conversation and friendly companionship?
              Perhaps a loved one needs social engagement to brighten their
              days. Our companion services offer a perfect solution. We provide
              enriching companionship for people of all ages, ensuring they
              never feel isolated or alone.
            </p>
          </div>
          <div className="col-md-4">
            <img
              // src={require("../Images/health2.jpeg")}
              // src={require("../Images/1.jpg")}
              src={require("../Images/a5.jpg")}
              className="img-fluid"
            />

            <h3 className="mt-2 fs-4"> Nursing Services</h3>

            <p className="description">
              Do you or a loved one need compassionate and professional nursing
              care in the comfort of home? We understand that navigating
              healthcare needs can be stressful. That's why our dedicated team
              of nurses is here to provide exceptional care, tailored to each
              client's specific needs.
            </p>
          </div>
          {/* <div className="col-md-3">
            <img
              // src={require("../Images/health1.jpeg")}
              src={require("../Images/2.jpg")}
              className="img-fluid"
              alt=""
            />

            <h3 className="mt-2 fs-5">Aged Care Services</h3>

            <p className="description">
              Our team of professionals caregivers and support workers are
              willing to provide you the following services washing,drying and
              putting away laundry of client sweeping....
            </p>
          </div> */}
        </div>
      </div>

      {/* here for you  */}

      <div className="text-center content-2">
        <div className="container">
          <h3 className="homeCareHeader">
            Home Isn't a Place, It's a Feeling: We bring care to you, for
            everyone.
          </h3>

          <p className="description">
            Discover how our skilled nurses, caring companions, and personal
            care assistants create a personalized approach that keeps your loved
            one healthy, happy, and engaged.
          </p>

          <div className="row">
            <div className="col-md-4">
              <div className="serviceCard">
                <a className="card1">
                  <p className="small">
                    <strong className="info3">
                      Sparkling smiles and fresh starts:
                    </strong>
                    Daily routines like showering and dressing, delivered with
                    loving care.
                  </p>
                  <div className="go-corner">
                    <div className="go-arrow">→</div>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <div className="serviceCard">
                <a className="card1">
                  <p className="small">
                    Gently reminding loved ones with dementia of the time and
                    place fosters a sense of calm and security.
                  </p>
                  <div className="go-corner">
                    <div className="go-arrow">→</div>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <div className="serviceCard">
                <a className="card1">
                  <p className="small">
                    Never miss a dose, we ensure timely medication reminders
                  </p>
                  <div className="go-corner">
                    <div className="go-arrow">→</div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* second row */}

          <div className="row">
            <div className="col-md-4">
              <div className="serviceCard">
                <a className="card1">
                  <p className="small">
                    Maintaining mobility with safe transfers for bed and bathing
                  </p>
                  <div className="go-corner">
                    <div className="go-arrow">→</div>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <div className="serviceCard">
                <a className="card1">
                  <p className="small">
                    We take care of errands so you can focus on what matters
                    most.
                  </p>
                  <div className="go-corner">
                    <div className="go-arrow">→</div>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <div className="serviceCard">
                <a className="card1">
                  <p className="small">
                    Peace of Mind for You, Comfort for Them
                  </p>
                  <div className="go-corner">
                    <div className="go-arrow">→</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
