import React from "react";
import "../css/MissionAndVision.css";

const MissionAndVision = () => {
  return (
    <div className="container missionContainer">
      <div className="row">
        <div className="col-md-4">
          <div class="e-card playing">
            <div class="image"></div>

            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>

            <div class="infotop">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="icon"
              ></svg>
              <br />
              Mission
              <br />
              <div class="name">
                Dedicated to fostering accessible, affordable, and
                patient-centered healthcare, our mission is to establish a
                transformative facility in Georgia. Rooted in a belief in
                healthcare as a human right, we commit to addressing diverse
                needs and contributing to the community's long-term well-being."
                "To enhance Georgia's healthcare landscape, we envision a
                state-of-the-art facility driven by a deep sense of
                responsibility. Our vision is to bridge gaps, provide essential
                services, and make a meaningful impact on individuals and
                families, ensuring equitable access to quality healthcare for
                all.
              </div>
            </div>
          </div>
        </div>
        {/* Values */}

        <div className="col-md-4">
          <div class="e-card playing">
            <div class="image"></div>

            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>

            <div class="infotop">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="icon"
              ></svg>
              <br />
              Values
              <br />
              <div class="name">
                Affectionate Angels Healthcare is not just a care service
                provider; we are a beacon of compassion and commitment to
                enriching the lives of the Georgia population. Our strategic
                location reflects our dedication to delivering personalized and
                culturally sensitive care solutions that go beyond routine
                services. At the core of our values lies a genuine commitment to
                enhancing the quality of life for each individual we serve,
                ensuring that our care service is not just specialized but truly
                impactful and aligned with the unique needs of our clients in
                the community.
              </div>
            </div>
          </div>
        </div>

        {/* objectives */}

        <div className="col-md-4">
          <div class="e-card playing">
            <div class="image"></div>

            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>

            <div class="infotop">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="icon"
              ></svg>
              <br />
              Objectives
              <br />
              <div class="name">
                To establish a transformative healthcare facility in Georgia,
                fostering accessibility, affordability, and patient-centered
                care while enriching lives and ensuring equitable access for
                all.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionAndVision;
