import React from "react";
import "./App.css";
import Menu from "./Components/Menu";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Hero from "./Components/Hero";
import SubHero from "./Components/SubHero";
import Intouch from "./Components/Intouch";
import Services from "./Components/Services";
import MissionAndVision from "./Components/MissionAndVision";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import Nursing from "./Services/Nursing";
import PersonalCare from "./Services/PersonalCare";
import CompanionService from "./Services/CompanionService";
import ChooseUs from "./Components/ChooseUs";
import About from "./Components/About";

function App() {
  return (
    <>
      <Header />
      <Menu />

      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/Contact" element={<Contact />}></Route>
          <Route exact path="/Nursing" element={<Nursing />}></Route>
          <Route exact path="/PersonalCare" element={<PersonalCare />}></Route>
          <Route exact path="/Companion" element={<CompanionService />}></Route>
          <Route exact path="/why-choose-us" element={<ChooseUs />}></Route>
          <Route exact path="/about" element={<About />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

function Home() {
  return (
    <>
      <Hero />
      <SubHero />
      <Intouch />
      {/* <MissionAndVision /> */}
      <Services />
    </>
  );
}

export default App;
