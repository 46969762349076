import React from "react";
import "../css/contact.css";

const Nursing = () => {
  return (
    <div>
      <div>
        <div className="container py-2 px-5">
          <div className="row">
            <div className="col-md-6">
              <div>
                <p className="text-dark" style={{ marginTop: 20 }}>
                  Do you or a loved one need compassionate and professional
                  nursing care in the comfort of home? We understand that
                  navigating healthcare needs can be stressful. That's why our
                  dedicated team of nurses is here to provide exceptional care,
                  tailored to each client's specific needs.
                </p>
                <p className="text-dark" style={{ marginTop: 10 }}>
                  Our registered nurses have extensive experience in a variety
                  of areas, including medication management, wound care, chronic
                  illness management, and post-surgical recovery. They will work
                  closely with you and your doctor to create a personalized care
                  plan that promotes healing and well-being.
                </p>
                <p className="text-dark" style={{ marginTop: 10 }}>
                  We go beyond just medical care. Our nurses offer companionship
                  and emotional support, ensuring your loved one feels safe and
                  comfortable at home. We can also assist with daily living
                  activities such as bathing, dressing, and meal preparation.
                  Let us focus on care, so you can focus on what matters most.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="global-section">
                <img
                  className="global-image"
                  // src={require("../Images/Services-Physical_Disability_Care.jpg")}
                  src={require("../Images/7.jpeg")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nursing;
