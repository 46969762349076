import React from "react";
import "../css/Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="main">
      <div className="container">
        <div className="row">
          {/* logo */}
          <div className="col-md-3 mt-2">
            <img
              src={require("../Images/logo2.jpeg")}
              className="logo  mb-2"
              alt=""
            />
            <p>
              <i>Helping Hands</i> <br /> <i>Healing Hearts</i> <br />
              <i>Happy Homes</i>
            </p>
          </div>

          {/* quick links */}
          <div className="col-md-2 mt-2">
            <h6 className="footer-header">Quick Links</h6>

            <div className="link-container">
              <Link to={"/about"} className="footer-link">
                About Us
              </Link>
            </div>

            <div className="link-container">
              <Link to={"/why-choose-us"} className="footer-link">
                Why Choose Us
              </Link>
            </div>
          </div>

          {/* extra */}
          <div className="col-md-2 mt-2">
            <h6 className="footer-header">Extra</h6>

            {/* <div className="link-container">
              <Link className="footer-link">Gallery</Link>
            </div> */}

            <div className="link-container">
              <Link to={"/Contact"} className="footer-link">
                Contact us
              </Link>
            </div>
          </div>

          {/* contact us */}
          <div className="col-md-5 mt-2">
            <h6 className="footer-header">Contact Us</h6>
            <div className="link-container">
              <Link className="footer-link">
                Address: 1636 Grassy Hill Ct, <br /> Grayson. GA, 30017 <br />{" "}
                GE-222-7538
              </Link>
            </div>
            <div className="link-container">
              <Link to={"tel:+1(774) 208-3004"} className="footer-link">
                Tel:+1(774) 208-3004
              </Link>
            </div>

            <div className="link-container">
              <Link
                className="footer-link"
                to={"mailto:info@affectionateangleshealthcare.com"}
              >
                Email: info@affectionateangelshealthcare.com
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
