import React from "react";
import "../css/contact.css";

const CompanionService = () => {
  return (
    <div>
      <div>
        <div className="container py-2 px-5">
          <div className="row">
            <div className="col-md-6">
              <div>
                <p className="text-dark" style={{ marginTop: 20 }}>
                  Do you crave stimulating conversation and friendly
                  companionship? Perhaps a loved one needs social engagement to
                  brighten their days. Our companion services offer a perfect
                  solution. We provide enriching companionship for people of all
                  ages, ensuring they never feel isolated or alone.
                </p>
                <p className="text-dark" style={{ marginTop: 10 }}>
                  Our compassionate companions are carefully screened and
                  matched with your individual interests and needs. Whether you
                  enjoy lively discussions, sharing hobbies, or simply
                  reminiscing, your companion will be there to listen, engage,
                  and offer emotional support. Together, you can embark on
                  outings, enjoy games and activities, or just relax in good
                  company.
                </p>
                <p className="text-dark" style={{ marginTop: 10 }}>
                  Our services are completely customizable. You can choose the
                  frequency and duration of visits that best suit your schedule.
                  Let us help you reconnect with the joy of social interaction
                  and create lasting memories.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="global-section">
                <img
                  className="global-image"
                  // src={require("../Images/Services-Physical_Disability_Care.jpg")}
                  // src={require("../Images/8.jpeg")}
                  src={require("../Images/a5.jpg")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanionService;
