import React from "react";
import "../css/contact.css";

const ChooseUs = () => {
  return (
    <div>
      <div>
        <div className="container py-2 px-5">
          <div className="row">
            <div className="col-md-6 order-md-1 order-2">
              <div>
                <p className="text-dark" style={{ marginTop: 20 }}>
                  When it comes to your health and well-being, you deserve the
                  very best. That's why our comprehensive healthcare program
                  offers a unique blend of services, including top-notch nursing
                  care designed to meet all your needs.
                </p>
                <p className="text-dark" style={{ marginTop: 10 }}>
                  Our team of qualified and experienced nurses are there to
                  administer medications, monitor vital signs, and provide
                  skilled care. They'll work closely with your doctor to ensure
                  you receive the exact level of medical attention you need,
                  right in the comfort of your own home.
                </p>
                <p className="text-dark" style={{ marginTop: 10 }}>
                  Beyond medical care, we understand the importance of feeling
                  supported and cared for. That's where our personal care
                  services come in. Our compassionate caregivers can assist with
                  daily living tasks such as bathing, dressing, and meal
                  preparation. They'll also provide companionship and a
                  listening ear, ensuring you feel safe, comfortable, and
                  connected.
                </p>
                <p className="text-dark" style={{ marginTop: 10 }}>
                  Most importantly, we believe in treating each client with the
                  utmost compassion and respect. Our caregivers go the extra
                  mile to understand your individual needs and preferences.
                  They'll create a personalized plan that fosters a sense of
                  dignity and independence, while ensuring your well-being is
                  always a priority.
                </p>
                <p className="text-dark" style={{ marginTop: 10 }}>
                  Don't settle for anything less than the best. Choose our
                  healthcare program and experience the difference that a
                  combination of skilled nursing, dedicated personal care, and
                  genuine compassion can make in your life.
                </p>
              </div>
            </div>
            <div className="col-md-6 order-md-2 order-1">
              <div className="global-section">
                <img
                  className="choose-us-img"
                  // src={require("../Images/Services-Physical_Disability_Care.jpg")}
                  src={require("../Images/happy-family-with-caregiver.jpg")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
