import Swal from "sweetalert2";
// import "sweetalert2/src/sweetalert2.scss";
import "../css/sweetalert.css";

class Helper {
  showMessage = (props) => {
    Swal.fire({
      title: props.title,
      text: props.text,
      icon: props.icon,
    });
  };

  showLoading = () => {
    Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  hideLoading = () => {
    Swal.close();
  };

  showDialog = (props) => {
    Swal.fire({
      title: props.title,
      text: props.text,
      icon: props.icon ? props.icon : "info",
      showCancelButton: props.showCancelButton,
      cancelButtonColor: props.cancelButtonColor
        ? props.cancelButtonColor
        : "red",
      confirmButtonColor: props.confirmButtonColor,
      confirmButtonText: props.confirmButtonText,
      cancelButtonText: props.cancelButtonText,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        props.proceed();
      } else {
        props.cancel();
      }
    });
  };

  verifyEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      return true;
    } else {
      return false;
    }
  };
}

const helper = new Helper();

export default helper;
